import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Loader from "@shared/loader";
import Empty from "@shared/empty";
const ListModal = () => import("@shared/components/listModal");
import Button from "@shared/components/button"

export default {
    name: "licence-list",
    components: {
        SubHeader,
        Loader,
        Empty,
        ListModal,
        Button
    },
    props: {},
    data: () => ({
        columns: [
            {
                label: "License Name",
                field: "name",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
            {
                label: "Description",
                field: "description",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
            {
                label: "Assigned Tenants",
                field: "tenants",
            },
            {
                label: "VALIDITY",
                field: "validity",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
        ],
        // dummy Data
        rows: [],
        loadingStates: {
            fetchingLicence: false,
            listModal: false,
            submit: false
        },
        licenseTenants: [],
        currentLicense: null,
        serchValues: ""
    }),

    computed: {
        getlicenseTenants() {
            return this.licenseTenants?.filter((el) => el.title?.toLowerCase()?.includes(this.serchValues.toLowerCase()));
        },
    },
    async mounted() {
        await this.getLicensePack();
    },
    methods: {
        createClicense() {
            this.$router.push({
                name: "license-add",
            });
        },
        async getLicensePack() {
            this.loadingStates.fetchingLicence = true;
            this.serchValues = ""
            try {
                let { data } = await axios.get("/license-pack");
                this.rows = data?.data || [];
            } catch (error) {
                console.log(error, "error");
            }
            this.loadingStates.fetchingLicence = false;
        },
        async handleShowModal(id) {
            this.serchValues = ""
            this.currentLicense = id
            this.$refs["license-tenants-modal"].showListModal();
            await this.getLicenseTenantsData(id);
        },
        handleCloseModal() {
            this.serchValues = ""
            this.currentLicense = null
            this.$refs["license-tenants-modal"].closeModal();
        },
        async getLicenseTenantsData(id) {
            this.serchValues = ""
            this.loadingStates.listModal = true;
            try {
                let {data} = await axios.get(`/license-pack/${id}/assigned_tenants`);
                this.licenseTenants =  data.assigned_tenants.map(el => ({...el, id:el.tenant_id})) || []
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.listModal = false;
        },
        removeTenants(tenant_id){
            this.licenseTenants = this.licenseTenants.filter(el => el.id !== tenant_id )
        },
        async assignTenants(licenseId){
            this.loadingStates.submit = true;
            try {
                let payload = {
                    license_pack_id: licenseId,
                    tenants: this.licenseTenants.map((tenant) => ({ tenant_id: tenant.id, license_count: tenant.license_count })),
                };
                let { data } = await axios.post(`/license-pack/allocate-tenant`, payload);
                this.$toast.success(data.message || "tenant assigned");
                this.handleCloseModal()
                this.getLicensePack()
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.submit = false;  
        },
        handleFilterResult(event) {
            this.serchValues = event;
        },
    },
};
